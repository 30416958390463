import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";
import { Container } from "../global";

const CTABanner = ({ links, subtitle, title }) => {
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <Content>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Links>
              {links.map(item => (
                <StyledLink to={item.link}>{item.name}</StyledLink>
              ))}
            </Links>
          </Content>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

CTABanner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  links: PropTypes.array,
};

CTABanner.defaultProps = {
  title: "",
  subtitle: "",
  links: [],
};

export default CTABanner;

const SectionWrapper = styled.section`
  padding: 40px 0;

  ${({ theme }) => theme.mq.xl`
  padding: 60px 0 50px 0;
  `}
`;

const ContentWrapper = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.secondary};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 24px;

  ${({ theme }) => theme.mq.xl`
  align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 322px;
    justify-content: center;
    padding: 60px 0 50px 0;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 560px;
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white.regular};
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 15px;
  margin-top: 0;
  text-align: center;

  ${({ theme }) => theme.mq.md`
  font-size: 35px;
    line-height: 45px;
  `}
`;

const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.white.regular};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 14px;
  margin-bottom: 28px;
  margin-top: 0;
  text-align: center;

  ${({ theme }) => theme.mq.md`
  line-height: 28px;
    font-size: 18px;
  `}
`;

const Links = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  background: ${({ theme }) => theme.colors.white.regular};
  border-radius: 100px;
  color: ${({ theme }) => theme.colors.black.original};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 12px;
  line-height: 14px;
  min-width: 140px;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 10px;
  }

  ${({ theme }) => theme.mq.md`
  font-size: 16px;
    line-height: 19px;
    min-width: 210px;
    padding: 20px 25px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  `}
`;
