import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";

import Header from "../components/sections/header";
import Footer from "../components/sections/footer";
import CTABanner from "../components/sections/ctaBanner";

const ctaBannerProps = {
  title: "Become an OPay Partner today",
  subtitle: `Try Shopify for free, and explore all the tools and services you need
    to start, run, and grow your business.`,
  links: [
    {
      name: "Submit your request",
      link: "#",
    },
    {
      name: "Contact Us",
      link: "/contact-us",
    },
  ],
};

const PartnersPage = () => (
  <Layout>
    <SEO title="OPay Partners" />
    <Navigation />
    <Header />

    <CTABanner {...ctaBannerProps} />
    <Footer />
  </Layout>
);

export default PartnersPage;
